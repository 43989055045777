/* @flow */
/**
 *
 * Displays a date (used in purchase history and payout history).
 * Supports different formats for English and German.
 *
 * Don't instantiate as `Date` as you will collide with native Date class.
 *
 */
import classnames from 'classnames';
import format from 'date-fns/format';
import counterpart from 'counterpart';

function DateComponent(props: { timestamp: string, className?: string }) {
  const dateFormat =
    counterpart.getLocale() === 'de' ? 'dd.MM.yyyy' : 'MMMM d, yyyy';
  return (
    <span className={classnames(props.className, { date: !props.className })}>
      {format(new Date(props.timestamp), dateFormat)}
    </span>
  );
}

export default DateComponent;
