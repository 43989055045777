/* @flow */
import { connect } from 'react-redux';

import { getIsDesktop, getIsMobile } from '../../../reducers/';

import { openModal } from '../../../helpers/actionCreators/redux';

import TooltipTrigger from './tooltipTrigger.jsx';

const mapStateToProps = (state) => ({
  isDesktop: getIsDesktop(state),
  isMobile: getIsMobile(state),
});

export default connect(mapStateToProps, { openModal })(TooltipTrigger);
