/* @flow */
/**
 * For displaying tooltip content in floating panel
 * Designed for use in conjuction with <TooltipTrigger/> and not alone
 *
 * Can specify positions that panel will display relative to
 */
import type { Node } from 'react';
import classnames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import * as C from '../../constants/misc';

import TooltipContent from './tooltipContent.jsx';

const handlePanelClick = (event: SyntheticInputEvent<HTMLElement>) => {
  event.stopPropagation();
};

function TooltipPanel(props: {
  open: boolean,
  noTranslate?: boolean,

  // defines content structure
  content: $ReadOnlyArray<Object>,
  children: Node,

  // preferred placement in relation to trigger
  panelPosition:
    | C.TOOLTIP_POSITION_ABOVE_CENTER
    | C.TOOLTIP_POSITION_BELOW
    | C.TOOLTIP_POSITION_BELOW_CENTER
    | C.TOOLTIP_POSITION_LEFT
    | C.TOOLTIP_POSITION_LEFT_UP
    | C.TOOLTIP_POSITION_RIGHT
    | C.TOOLTIP_POSITION_BELOW_LEFT,
}) {
  const className = classnames(
    'g_tooltip_panel',
    `g_tooltip_panel-position-${
      props.panelPosition || C.TOOLTIP_POSITION_BELOW
    }`
  );

  return (
    <TransitionGroup>
      {props.open && (
        <CSSTransition
          classNames="g_tooltip_transition"
          timeout={{ enter: 300, exit: 300 }}>
          <div className={className} onClick={handlePanelClick}>
            <div className="g_tooltip_panel_inner">
              <div className="g_tooltip_panel_content">
                {props.children}
                {props.content && (
                  <TooltipContent
                    noTranslate={props.noTranslate}
                    content={props.content}
                  />
                )}
                <div className="g_tooltip_panel_arrow" />
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

export default TooltipPanel;
