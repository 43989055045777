/* @flow */
/**
 * Displaying a price (no internationalisation supported
 * at this point in time, as we only have $$$'s anyways)
 */
import classnames from 'classnames';
import styled from 'styled-components';

import {
  TEST_CHECKOUT_SUMMARY_SUBTOTAL,
  TEST_PRICE_COMPONENT,
} from '../../constants/pageObjectSelectors';
import { formatPrice } from '../../helpers/tools';

const StyledSpan = styled.span`
  white-space: nowrap;
  text-decoration: ${({ inUndiscountedSubtotal }) =>
    inUndiscountedSubtotal && 'line-through'};
`;

function Price(props: {
  // value could be string due to API
  amount: number | string,

  // USD | EUR etc
  currency: string,

  // is the price pending, if so grey out current price
  pending?: boolean,

  // should we show the currency after the amount
  // by default we don't
  showCurrency?: boolean,
  className?: string,
  // truncate cents
  truncate?: boolean,
  // used to add data-test-id on the checkout summary total price
  inUndiscountedSubtotal?: boolean,
}) {
  // TODO: make these propTypes required and put this logic around the price components themselves
  let value;
  if (props.currency && props.amount) {
    value = formatPrice(props.amount, props.currency, props.truncate);
  }
  const className = classnames(
    'g_price',
    {
      'g_price-pending': props.pending,
    },
    props.className
  );

  return (
    <StyledSpan
      className={className}
      inUndiscountedSubtotal={props.inUndiscountedSubtotal}
      data-test-id={
        props.inUndiscountedSubtotal
          ? TEST_CHECKOUT_SUMMARY_SUBTOTAL
          : TEST_PRICE_COMPONENT
      }>
      {props.amount && <span>{value}</span>}
      {props.currency && props.showCurrency && (
        <span>{` ${props.currency}`}</span>
      )}
    </StyledSpan>
  );
}
export default Price;
