/* @flow */
import { Close } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';

import T from '../general/translate.jsx';
import TooltipContent from '../general/tooltipContent.jsx';

function TooltipModal(props: {
  tooltipContent: Object,
  headline: string,
  className?: string,
  close: CloseModal,
}) {
  return (
    <div className={props.className}>
      <div className="modal_content" style={{ textAlign: 'left' }}>
        <T
          noTranslate
          component="button"
          eventName="sellerSignup_close"
          eventAction="click_icon"
          eventLabel="close icon"
          eventPosition="sellerSignup_landing"
          eventType="click_inbound"
          className="dont-be-a-button modal_close"
          onClick={props.close}>
          <ThemeProvider>
            <Close color="grey50" />
          </ThemeProvider>
        </T>
        <T
          component="h2"
          className="typo_footerSection typo_footerSection-bold typo-topMarginBoost-small">
          {props.headline}
        </T>
        <TooltipContent inModal content={props.tooltipContent} />
      </div>
    </div>
  );
}

export default TooltipModal;
