/* @flow */
/**
 * For displaying tooltip content in floating panel
 * Designed for use in conjuction with <TooltipPanel/> or <TooltipModal /> and not alone
 */

import classnames from 'classnames';

import t from 'counterpart';

import T from '../general/translate.jsx';

function TooltipContent(props: {
  // eslint-disable-next-line react/no-unused-prop-types
  noTranslate: boolean, // used inside a .map()
  content: $ReadOnlyArray<Object>,
  // adds different classes when used in modal
  inModal?: boolean,
}) {
  return (
    <div>
      {props.content.map((paragraph, i) => {
        const markup = [];
        if (paragraph.title) {
          let string = paragraph.title;
          if (string.split('.').length > 1) {
            string = t(paragraph.title);
          }
          markup.push(
            <T
              key={`title-${paragraph.title}`}
              noTranslate
              className={classnames({
                g_tooltip_panel_title: !props.inModal,

                g_tooltip_panel_extraTitle: i > 0 && !props.inModal,
                'typo_footerSection typo_footerSection-bold typo-topMarginBoost-small':
                  props.inModal,
              })}>
              {string}
            </T>
          );
        }
        if (paragraph.body) {
          markup.push(
            <T
              key={`title-${paragraph.body}`}
              noTranslate={props.noTranslate}
              bodyLink={
                paragraph.bodyLinkUrl && (
                  <T
                    noTranslate={props.noTranslate}
                    component="a"
                    className="g_link g_link-aqua"
                    target="_blank"
                    rel="noopener"
                    href={paragraph.bodyLinkUrl}>
                    {paragraph.bodyLink}
                  </T>
                )
              }
              className={classnames({
                g_tooltip_panel_body: !props.inModal,

                typo_pStatic: props.inModal,
              })}>
              {paragraph.body}
            </T>
          );
        }
        if (paragraph.href) {
          markup.push(
            <T
              key={`title-${paragraph.href}`}
              noTranslate={props.noTranslate}
              className={classnames('g_link g_link-aqua', {
                g_tooltip_panel_link: !props.inModal,

                typo_pStatic: props.inModal,
              })}
              component="a"
              href={paragraph.href}
              target="_blank">
              {paragraph.link}
            </T>
          );
        }
        return markup;
      })}
    </div>
  );
}

TooltipContent.defaultProps = {
  inModal: false,
};

export default TooltipContent;
