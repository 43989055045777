/* @flow */
import T from '../general/translate';

import TransactionItem from './transactionItem/';
import TransactionsEmpty from './transactionsEmpty';

function OrderHistory(props: {
  data: {
    transactions: EyeEmTransactions,
  },
}) {
  return (
    <div className="page_content">
      <div className="row">
        <div className="small-12 medium-12 large-12 columns">
          <T component="h2" className="pp_headline">
            transactions.headline
          </T>
        </div>
      </div>
      <div className="row">
        <div className="small-12 medium-8 large-7 columns">
          <div className="cw_container pp_content">
            {props.data?.transactions?.items.map((transaction) => (
              <TransactionItem key={transaction.id} transaction={transaction} />
            ))}
            {(!props.data?.transactions ||
              props.data?.transactions?.items?.length === 0) && (
              <TransactionsEmpty key="empty" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderHistory;
