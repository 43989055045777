/* @flow */
/* `i` button for opening tooltips or modals, uses a <TooltipPanel/> to display content */
/* eslint-env browser */

import type { Node } from 'react';
import { useState } from 'react';
import t from 'counterpart';
import styled from '@emotion/styled';
import { Info } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';

import TooltipPanel from '../tooltipPanel.jsx';
import TooltipModal from '../../modals/tooltip.jsx';

import {
  TOOLTIP_POSITION_ABOVE_CENTER,
  TOOLTIP_POSITION_ABOVE_RIGHT,
  TOOLTIP_POSITION_BELOW,
  TOOLTIP_POSITION_BELOW_CENTER,
  TOOLTIP_POSITION_BELOW_LEFT,
  TOOLTIP_POSITION_LEFT,
  TOOLTIP_POSITION_LEFT_UP,
  TOOLTIP_POSITION_RIGHT,
} from '../../../constants/misc';

import { track } from '../../../helpers/tools';

const StyledTooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  padding-right: ${(props) => (props.isMobile ? '8px' : null)};
`;

type Props = {
  'data-test-id'?: string,
  children: Node,
  className?: string,
  content: $ReadOnlyArray<Object>,
  eventEcommerce?: Object,
  eventName?: string,
  eventPosition?: string,
  eventPositionIndex?: number,
  eventValue?: string,
  isDesktop?: boolean,
  isMobile: boolean,
  noTranslate?: boolean,
  openModal: Function,
  panelPosition?:
    | TOOLTIP_POSITION_ABOVE_CENTER
    | TOOLTIP_POSITION_ABOVE_RIGHT
    | TOOLTIP_POSITION_BELOW
    | TOOLTIP_POSITION_BELOW_CENTER
    | TOOLTIP_POSITION_BELOW_LEFT
    | TOOLTIP_POSITION_LEFT
    | TOOLTIP_POSITION_LEFT_UP
    | TOOLTIP_POSITION_RIGHT,
  style?: Object,
  trigger?: Node,
};

const TooltipTrigger = ({
  'data-test-id': dataTestId,
  children,
  className,
  content,
  eventEcommerce,
  eventName,
  eventPosition,
  eventPositionIndex,
  eventValue,
  isDesktop,
  isMobile,
  noTranslate,
  openModal,
  panelPosition,
  style,
  trigger,
}: Props) => {
  const [open, setOpen] = useState(false);

  const trackIfRequired = () => {
    if (eventPosition || eventPositionIndex) {
      track({
        eventType: t('tracking.eventType.inbound'),
        eventValue,
        eventName: eventName || 'access_tooltip',
        eventAction: t('tracking.eventAction.hover'),
        eventLabel: t('tracking.eventLabel.icon'),
        eventPosition,
        eventPositionIndex,
        ecommerce: eventEcommerce,
      });
    }
  };

  const handleMouseEnter = () => {
    setOpen(true);
    trackIfRequired();
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const handleClick = () => {
    track({
      eventType: t('tracking.eventType.inbound'),
      eventName: 'access_modal_tooltip',
      eventAction: t('tracking.eventAction.icon'),
      eventLabel: 'tooltip',
      eventPosition,
    });
    openModal({
      content: TooltipModal,
      contentProps: {
        tooltipContent: content,
      },
    });
  };

  return (
    <ThemeProvider>
      <StyledTooltipWrapper
        isMobile={isMobile}
        className={className}
        style={style}
        onMouseEnter={isDesktop ? handleMouseEnter : null}
        onMouseLeave={isDesktop ? handleMouseLeave : null}
        onClick={!isDesktop ? handleClick : null}>
        {!trigger && (
          <div className="g_tooltip_trigger" data-test-id={dataTestId}>
            <Info color="grey50" />
          </div>
        )}
        {trigger}
        <TooltipPanel
          noTranslate={noTranslate}
          captureClicks={!isDesktop}
          open={open}
          panelPosition={panelPosition}
          content={content}>
          {children}
        </TooltipPanel>
      </StyledTooltipWrapper>
    </ThemeProvider>
  );
};

TooltipTrigger.defaultProps = {
  eventPosition: '',
  panelPosition: TOOLTIP_POSITION_BELOW,
};

export default TooltipTrigger;
