/* @flow */
import t from 'counterpart';
import styled from '@emotion/styled';
import { format, addYears } from 'date-fns';

import { Flex, Box, Text } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';
import TooltipTrigger from '../../general/tooltipTrigger/';
import Price from '../../general/price';
import PremiumLabel from '../../general/premiumLabel';

const StyledDealWrapper = styled(Box)`
  width: 84px;
`;

const StyledTitle = styled(Text)`
  line-height: 1;
`;

const StyledPremiumLabel = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
`;

/* TODO: ThemeProvider
         https://github.com/system-ui/theme-ui/pull/782
*/
const StyledBoxContainer = styled(Flex)`
  border-top: 1px solid #e5e5e5;
`;

const StyledLicenseDataWrapper = styled(Flex)`
  width: 100%;
`;

function TransactionItemBodyDeal(props: { deal: EyeEmCartItemDeal }) {
  const licenseId = props.deal.licenseType.id;
  const isPremium = props.deal.premiumPrice !== -1;
  const price = props.deal.fullPrice;

  return (
    <ThemeProvider>
      <StyledBoxContainer pt={3} flexDirection="row">
        <StyledDealWrapper>
          <img alt="deal icon" src="/node-static/img/checkout/deal.svg" />
          {isPremium && (
            <StyledPremiumLabel>
              <PremiumLabel />
            </StyledPremiumLabel>
          )}
        </StyledDealWrapper>
        <StyledLicenseDataWrapper pt={1} pl={3} justifyContent="space-between">
          <Flex flexDirection="column">
            <Flex pb={1}>
              <Box pr={2}>
                <Price amount={price} currency={props.deal.currency} truncate />
              </Box>
              <StyledTitle color="grey20">
                {t(`licenses.types.${licenseId}.title`)}
              </StyledTitle>
            </Flex>
            <Text color="grey50">
              {t('checkout.cartItem.deal.details.downloadAmount', {
                amount: props.deal.maxDownloads,
              })}
            </Text>
            <Text color="grey50">
              {t('checkout.cartItem.deal.details.expiry', {
                date: format(addYears(new Date(), '1'), 'MM/dd/yyyy'),
              })}
            </Text>
          </Flex>
          <TooltipTrigger
            eventPosition="purchase_history"
            content={[
              {
                title: `licenses.types.${licenseId}.title`,
                body: `licenses.types.${licenseId}.body`,
              },
            ]}
          />
        </StyledLicenseDataWrapper>
      </StyledBoxContainer>
    </ThemeProvider>
  );
}

export default TransactionItemBodyDeal;
