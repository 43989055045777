// @flow
import { gql } from '@apollo/client';

import { SimplePhotoFragment } from '../fragments/photo';

export const GET_TRANSACTION = gql`
  query getTransaction($transactionId: ID!) {
    transaction(transactionId: $transactionId) {
      id
      netAmount
      grossAmount
      undiscountedNetAmount
      taxRate
      currency
      paymentId
      photos {
        ...SimplePhoto
        market {
          modelAmount
          propertyAmount
          licensing {
            purchased {
              price
              currency
            }
          }
        }
      }
      photosFromPacks {
        photoId
      }
      dealTemplates {
        id
        fullPrice
        dealType
        maxDownloads
        licenseType {
          id
          name
        }
      }
      invoiceUrl
      photoZipUrl
      adyenPaymentMethodType
      discount {
        code
        undiscountedNet
        percentage
      }
    }
  }
  ${SimplePhotoFragment}
`;

export const GET_TRANSACTIONS = gql`
  query getTransactions {
    transactions {
      items {
        id
        createdAt
        grossAmount
        currency
        photos {
          id
          previewUrl: photoUrl
          market {
            licensing {
              purchased {
                id
                price
                currency
              }
            }
          }
        }
        dealTemplates {
          id
          fullPrice
          currency
          premiumPrice
          maxDownloads
          licenseType {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_TRANSACTION_INVOICE_URL = gql`
  query getTransactionInvoiceUrl($transactionId: ID!) {
    transactionInvoiceUrl(transactionId: $transactionId) {
      url
    }
  }
`;

export const GET_TRANSACTION_PHOTOS_URL = gql`
  query getTransactionPhotosUrl($transactionId: ID!) {
    transactionPhotosUrl(transactionId: $transactionId) {
      url
    }
  }
`;
