import T from '../general/translate.jsx';
import Link from '../general/link/';

import { SEARCH_BASE_URL } from '../../helpers/search';

function TransactionsEmpty() {
  return (
    <div className="cw_inner pp_empty">
      <T component="p">transactions.empty.text</T>
      <T component={Link} href={SEARCH_BASE_URL} className="g_link g_link-aqua">
        transactions.empty.searchLink
      </T>
    </div>
  );
}

export default TransactionsEmpty;
