/* @flow */
import get from 'lodash/get';
import t from 'counterpart';
import styled from '@emotion/styled';

import { Flex, Box, Text } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';

import TooltipTrigger from '../../general/tooltipTrigger/';
import Price from '../../general/price';
import Photo from '../../general/photo';

const StyledTitle = styled(Text)`
  line-height: 1;
`;

/* TODO: ThemeProvider
         https://github.com/system-ui/theme-ui/pull/782
*/
const StyledBoxContainer = styled(Flex)`
  border-top: 1px solid #e5e5e5;
`;

const StyledLicenseDataWrapper = styled(Flex)`
  width: 100%;
`;

const StyledPhotoWrapper = styled.div`
  width: 84px;
`;
function TransactionItemBodyPhoto(props: { photo: EyeEmPhoto }) {
  const purchases = get(props, 'photo.market.licensing.purchased');
  const panelTranslationPrefix = `licenses.types.${purchases?.id}`;

  return (
    <ThemeProvider>
      <StyledBoxContainer py={3} flexDirection="row">
        <StyledPhotoWrapper>
          <Photo
            url={props.photo.previewUrl}
            size1="sq"
            size2={64}
            link={`/p/${props.photo.id}`}
          />
        </StyledPhotoWrapper>
        <StyledLicenseDataWrapper pt={1} pl={3} justifyContent="space-between">
          <Flex flexDirection="column">
            <Flex>
              <Box pr={2}>
                <Price
                  amount={purchases.price}
                  currency={purchases.currency}
                  truncate
                />
              </Box>
              <StyledTitle color="grey20">
                {t(`${panelTranslationPrefix}.title`)}
              </StyledTitle>
            </Flex>
          </Flex>
          <TooltipTrigger
            eventPosition="purchase_history"
            content={[
              {
                title: `${panelTranslationPrefix}.title`,
                body: `${panelTranslationPrefix}.body`,
              },
            ]}
          />
        </StyledLicenseDataWrapper>
      </StyledBoxContainer>
    </ThemeProvider>
  );
}

export default TransactionItemBodyPhoto;
