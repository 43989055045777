/* @flow */
/* For displaying a transaction in purchase history */
/* eslint-env browser */
import { useLazyQuery } from '@apollo/client';

import T from '../../general/translate';
import DateComponent from '../../general/date';
import Price from '../../general/price';
import { Box } from '@eyeem-ui/atoms';

import TransactionItemBodyDeal from './transactionItemBodyDeal';
import TransactionItemBodyPhoto from './transactionItemBodyPhoto';

import { deprecatedCreateFileDownload } from '../../../helpers/tools';

import {
  GET_TRANSACTION_INVOICE_URL,
  GET_TRANSACTION_PHOTOS_URL,
} from '../../../graphql/queries/transaction';

type Props = {
  transaction: EyeEmTransaction,
};

const TransactionItem = (props: Props) => {
  const [getTransactionInvoiceUrl] = useLazyQuery(GET_TRANSACTION_INVOICE_URL, {
    variables: { transactionId: props.transaction.id },
    onCompleted: (data) => {
      downloadFile(data.transactionInvoiceUrl.url);
      window.dataLayer.push({
        event: 'click_inbound',
        eventInfo: {
          eventName: 'ecom_purchase_invoice',
          eventAction: 'click_button',
          eventLabel: 'invoice_download',
          eventPosition: 'purchaseHistory',
        },
      });
    },
  });
  const [getTransactionPhotosUrl] = useLazyQuery(GET_TRANSACTION_PHOTOS_URL, {
    variables: { transactionId: props.transaction.id },
    onCompleted: (data) => {
      downloadFile(data.transactionPhotosUrl.url);
      window.dataLayer.push({
        event: 'click_inbound',
        eventInfo: {
          eventName: 'ecom_purchase_download',
          eventAction: 'click_button',
          eventLabel: 'image_download',
          eventPosition: 'purchaseHistory',
        },
      });
    },
  });

  const downloadFile = (url: string) => deprecatedCreateFileDownload(url);

  const getDownload = (event: SyntheticInputEvent<HTMLElement>) => {
    event.preventDefault();
    getTransactionPhotosUrl();
  };

  const getInvoice = (event: SyntheticInputEvent<HTMLElement>) => {
    event.preventDefault();
    getTransactionInvoiceUrl();
  };

  const { transaction } = props;
  const photos = transaction?.photos || [];
  const dealTemplates = transaction?.dealTemplates || [];

  return (
    <div className="pp_item">
      {transaction && (photos || dealTemplates?.length > 0) && (
        <div className="pp_itemHeader">
          <h4 className="pp_itemHeadline">
            <DateComponent timestamp={transaction.createdAt} />
            <Price
              className="float-right"
              amount={transaction.grossAmount}
              currency={transaction.currency}
            />
          </h4>

          {photos.length > 0 && (
            <T className="pp_licenseLabel" count={photos.length}>
              transactions.photoLicenses
            </T>
          )}
          {photos.length > 0 && (
            <T
              className="g_link g_link-aqua pp_downloadLink"
              count={photos.length}
              onClick={getDownload}
              download={
                <T className="pp_downloadLink-label" target="_blank">
                  transactions.download
                </T>
              }>
              transactions.downloadImages
            </T>
          )}
          <T
            className="g_link g_link-aqua pp_downloadLink"
            onClick={getInvoice}
            download={
              <T className="pp_downloadLink-label">transactions.download</T>
            }
            target="_blank">
            transactions.downloadInvoice
          </T>
        </div>
      )}
      <Box pt={4}>
        {photos.length > 0 &&
          photos.map((photo) => (
            <TransactionItemBodyPhoto key={photo.id} photo={photo} />
          ))}
        {dealTemplates.length > 0 &&
          dealTemplates.map((deal) => (
            <TransactionItemBodyDeal key={deal.id} deal={deal} />
          ))}
      </Box>
    </div>
  );
};

export default TransactionItem;
